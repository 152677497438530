import React from 'react';
import classes from "./FormLogout.module.css";
const formLogin = (props) => {
    return(
        <form className="form-inline my-2 my-lg-0" encType="application/x-www-form-urlencoded"
              onSubmit={(event )=>props.logout(event)}
        >
                <label>{props.userName}</label>
            <div className="input-group input-group-sm mb-1 mr-sm-2">
                <button  className="btn btn-success btn-sm">LOGOUT</button>
            </div>
        </form>
    )
}

export default formLogin;