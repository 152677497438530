import React, { Component } from 'react';
import $ from 'axios';
import VideoSessionItem from './VideoSessionItem/VideoSessionItem';
//import '../../css/navbar.css';
import Spinner  from '../UI/Spinner/Spinner';
import classes from './VideoSessions.module.css';

const mergedClass= [
    classes.ListPresentations
].join(" ");





class VideoSessions extends Component {
    constructor(props){
        super(props);
        this.state = {

            dowloadedVideoSessions: {
                status: "",
                videoSessions: []
            }
        }
        $.get = $.get.bind(this);
    }

    componentDidMount(){
        const derivedSearch = this.props.location.search;
        let search = "?all=yes";
        if (derivedSearch.length>0) {
             search = derivedSearch;
        }
        //console.log("++++++++++++++++++++++++++++++Search:", search);
        $.defaults.baseURL = "https://k4djcmsig3.execute-api.us-east-1.amazonaws.com/dev/returnvideosessionsget";
        //const url = "https://k4djcmsig3.execute-api.us-east-1.amazonaws.com/dev/returnvideosessionsget?videoSessionId=qy1p98vrj41j";
        //const url = "https://k4djcmsig3.execute-api.us-east-1.amazonaws.com/dev/returnvideosessionsget?vendor=Netapp&ownerName=Arrow";

        $.get(search).then(response => {
            const dowloadedVideoSessions = response.data;
            this.setState({dowloadedVideoSessions: dowloadedVideoSessions})
            //console.log(this.state.dowloadedVideoSessions)
        }).catch((error) => {console.log("Something went wrong on session downloads",error.response.status)});
        
        
    }

    render(){
        if (this.state.dowloadedVideoSessions.status === "200" && this.state.dowloadedVideoSessions.videoSessions.length>0){
            let videoSessionItemMap = this.state.dowloadedVideoSessions.videoSessions.map(videoSession => {

                return <VideoSessionItem  key = {videoSession.c} videoSession = {videoSession}/>
            })
            return (
                    <div className={"card-columns"} >
                        {videoSessionItemMap}
                    </div>

            )
        }
        else if (this.state.dowloadedVideoSessions.status === ""){
            return (
                <div className={mergedClass}>
                    <Spinner/>
                </div>

            )
        }

     else {
            return(
                <div>
                    <span className="badge badge-warning">No video sessions with the above criterias</span>
                </div>
            )
        }


    }
}

export default VideoSessions;