import React, {Component} from 'react';
import update from 'immutability-helper';
import Href from './NavBar/Href/Href';
import Login from './NavBar/Auth/Authentication';
import {connect} from "react-redux";

import Logo from "../../assets/images/itmeadow.png";
import classes from "./HeaderV2.module.css";



class HeaderV2 extends Component {

    constructor(props) {
        super(props);

        this.state = {
            links: [
                {
                    ref: '/Registration',
                    label: 'REGISTRATION',
                    style: {}
                },

                {
                    ref: '/',
                    label: 'PUBLISHED PRESENTATIONS',
                    style: {},
                    protected: false,
                },
                {
                    ref: '/about',
                    label: 'ABOUT US',
                    style: {}
                },
                {
                    ref: '/test',
                    label: 'CHAT',
                    style: {}
                },
            ]
            ,
            lastActive: null,
        }
    }

    clickHandler = (key) => {
        if (key===this.state.lastActive) {
            return;
        }
        const destStyle = {
            //font: '14px "Open Sans"'
        };

        let links={};
        if (this.state.lastActive!=null) {
            links = update(this.state.links,{
                [key]: {
                    style: {
                        $set: destStyle
                    }
                },
                [this.state.lastActive]: {
                    style: {
                        $set: {}
                    }
                },
            });

        } else {
            links = update(this.state.links,{
                [key]: {
                    style: {
                        $set: destStyle
                    }
                },

            });

        }

        this.setState({
            links:links,
            lastActive: key
        });
    }

    render() {
        let hrefs = this.state.links.filter( (href) => {
                return  !href.protected || this.props.loggedIn
            }
        ).map( (href, index) => {
                return <Href onclick={() => this.clickHandler(index)}
                             caption={href.label}
                             style={href.style}
                             key={index}
                             link={href.ref}
                             index={index}
                />
            }
        );

        return(
            <div className={classes.Header}>
                <nav className="navbar navbar-expand-lg navbar-light bg-light justify-content-end">
                    <img src={Logo} width="126px" height="36px" alt="" className="mr-auto"/>

                    <button className="navbar-toggler" type="button" data-toggle="collapse"
                            data-target="#navbarSupportedContent">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse flex-grow-0" id="navbarSupportedContent">
                        <ul className="navbar-nav text-right">
                            {hrefs}
                        </ul>
                    </div>

                    <Login/>
                </nav>
            </div>
        );
    }


}

const mapStateToProps = (state) => {
    return {
        loggedIn: state.loggedIn,
    }
};


export default connect(mapStateToProps,null)(HeaderV2);
