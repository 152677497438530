import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import VideoSessionDescription from './VideoSessionDescription/VideoSessionDescription';
//import VideoScreen from './VideoScreen/VideoScreen';
import moment from 'moment';

//{a=videoSessionToEnd,
// b=description,
// c=videoSessionId,
// d=presenterName,
// e=title,
// f=duration,
// g=ownerName,
// h=vendor,
// i=videoSessionToStart,
// j=categories,
// k=status}

import classes from './VideoSessionItem.module.css';

const mergedClass= [
    classes.VideoSessionItem,
    "card"
].join(" ");


class VideoSessionItem extends Component {
    constructor(props) {
        super(props);
        this.state={
            hidden: true,
            joined: false,
            timeUntilStart: null
        };
        this.joinSession = this.joinSession.bind(this);
        this.timeUntilStart = this.timeUntilStart.bind(this);
        this.showTimeUntilStart = this.showTimeUntilStart.bind(this);
    }

    joinSession(){
        this.props.history.push('/JoinToVideoSession/'+this.props.videoSession.c.replace(":", ""),this.props.videoSession);

    }

    timeUntilStart=()=> {
        //moment.duration(end_date.diff(start_date));
        const startTime = moment(this.props.videoSession.i, "YYYY.MM.DD HH:mm");//.format("YYYY.MM.DD HH:mm");
        const now = moment(new Date());
        if (now>=startTime) {
            //moment(values.startDate).add(values.duration, 'minutes')
            if (startTime.add(this.props.videoSession.f, 'minutes')>now) {
                return "Show-Time";
            } else {
                return "Finished";
            }
        }
        const duration = moment.duration(startTime.diff(now));
        let returnDuration =  duration._data.seconds;
        returnDuration = returnDuration<2 ? returnDuration+" second " : returnDuration+ " seconds ";


        if (duration._data.minutes<1) {
            return returnDuration;
        }
        const minutes = duration._data.minutes<2 ? " minute " : " minutes ";
        returnDuration = duration._data.minutes + minutes + returnDuration;

        if (duration._data.hours<1) {
            return returnDuration;
        }
        const hours = duration._data.minutes<2 ? " hour " : " hours ";
        returnDuration = duration._data.hours + hours + returnDuration;

        if (duration._data.days<1) {
            return returnDuration;
        }
        const days = duration._data.days<2 ? " day " : " days ";
        returnDuration = duration._data.days + days + returnDuration;

        if (duration._data.months<1) {
            return returnDuration;
        }
        const months = duration._data.months<2 ? " month " : " months ";
        returnDuration = duration._data.months + months + returnDuration;

        if (duration._data.years<1) {
            return returnDuration;
        }
        const years = duration._data.months<2 ? " year " : " years ";
        returnDuration = duration._data.years + years + returnDuration;


        return returnDuration;
    }

    showTimeUntilStart(){
        const duration = this.timeUntilStart();

        this.setState( (prevState) =>{
            return {
                ...prevState,
                timeUntilStart: duration,
            }
        });
        if (duration==="Finished") {
            clearInterval(this.timer);
        }
    }

    componentWillMount() {
        this.timer = setInterval(this.showTimeUntilStart, 1000);
    }
    componentWillUnmount() {
        clearInterval(this.timer);
        console.error("+++++++++++", "cleared");
    }

    render(){

        console.log(this.props.videoSession);

        let videoSessionDescription = <VideoSessionDescription description = {this.props.videoSession.b}/>
        const duration = this.timeUntilStart();
        return(
                <div className={mergedClass} id={this.props.videoSession.c.replace(":", "")+"Pointer"} >
                    <>

                         <div className="card-header" id={this.props.videoSession.c.replace(":", "")+"DEPRECATED_Header"} >
                            <h5 className="mb-0" >
                                <button className="btn btn-link"
                                        type="button"
                                        disabled={ this.props.videoSession.k !== 'started' }
                                        onClick={this.joinSession}
                                        style={{textTransform: "uppercase"}}
                                >
                                    {this.props.videoSession.e}
                                </button>
                            </h5>
                         </div>

                        <div className="card-body" >
                            <p> Presentation by: <strong>{this.props.videoSession.d} </strong></p>
                            <p> Vendor: <strong> {this.props.videoSession.h} </strong> </p>
                            <p> {"Starts at: "+this.props.videoSession.i} </p>
                            <p> {"Duration: "+this.props.videoSession.f} </p>
                        </div>

                        <div className="card-footer" style={{display: "flex", justifyContent: "space-between"}} >
                                <button
                                    type="button"
                                    className={classes["link-button"]}
                                    data-toggle="collapse"
                                    data-target={"#"+this.props.videoSession.c.replace(":", "")+"Content"}
                                    aria-expanded="false"
                                    aria-controls={this.props.videoSession.c.replace(":", "")+"Content"}
                                >
                                    DESCRIPTION
                                </button>
                            <span >{this.state.timeUntilStart}</span>

                        </div>
                    </>
                    <div id={this.props.videoSession.c.replace(":", "")+"Content"} className="collapse" aria-labelledby={this.props.videoSession.c.replace(":", "")+"DEPRECATED_Header"} data-parent={"#"+this.props.videoSession.c.replace(":", "")+"Pointer"}>
                            <div className="card-body">
                                <div>
                                    <em>{videoSessionDescription}</em>
                                </div>
                            </div>
                        </div>
                    </div>

        );
    }
}

export default withRouter(VideoSessionItem);