import React, {Component} from 'react';
import AWS from "aws-sdk";
import GetCredentials from "../../../../Utils/aws/GetCredentials";
import { Launcher } from "rc-chat-view";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import awsConfig from "../../../../../configs/aswConfig";
import ApiGwRequest from "../../../../Utils/aws/ApiGWRequest";




class Chat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            messageList: [],
            chatVisible: false,
        };
        this.receiveMessage = this.receiveMessage.bind(this);
        this.startChat = this.startChat.bind(this);

        this.disMounted = false;
        this.sqs = null;
        this.sns = null;
        this.poller = null;
        this.messageReceiver = null;
    }

    componentDidMount() {
        const userPoolUrl = "https://cognito-idp."+awsConfig.region+".amazonaws.com/"+awsConfig.userPoolId;
        const requestBody = {
            token : this.props.token,
            userPoolUrl: userPoolUrl,
            requiredToCheck: {
                "CHECK_APPCLIENTID": awsConfig.userPoolWebClientId,
                "CHECK_TOKENTYPE": "id",
                "CHECK_USERPOOL": userPoolUrl,
            },
            videoSessioId: this.props.videoSessionId,
        };

        ApiGwRequest('https://a77rgykiy0.execute-api.us-east-1.amazonaws.com','/0001/handlepresenterchatresources','POST', requestBody)
            .then(result =>
                {
                    if (result.data.status==="200") {
                        this.startChat();
                    }

                }
            ).catch(rejected => {
            this.stateUpdater({chatVisible: false});
            console.log(rejected);
        });



    }

    startChat(){
        const self = this;

        GetCredentials().then((creds) => {
            //https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/SQS.html
            const awsCredentials = new AWS.Credentials(
                creds.data.Credentials.AccessKeyId,
                creds.data.Credentials.SecretKey,
                creds.data.Credentials.SessionToken
            );


            const optionsSQS = {
                region: "us-east-1",
                credentials: awsCredentials,
                correctClockSkew: true,
                apiVersion: '2012-11-05',
            };

            const optionsSNS = {
                region: "us-east-1",
                credentials: awsCredentials,
                correctClockSkew: true,
                apiVersion: '2010-03-31',
            };

            self.sqs = new AWS.SQS(optionsSQS);
            self.sns = new AWS.SNS(optionsSNS);
            /*
               final String sqsQName = String.format("chat-%-%",routingRequest.videoSessioId,ownerId);
               final String snsName = String.format("chat-%",routingRequest.videoSessioId);

             */

            const qName= "chat-"+self.props.videoSessionId+"-"+self.props.cognitoUserName;
            const params = {
                QueueName: qName, /* required */
            };
            console.log("QName:"+qName);
            self.sqs.getQueueUrl(params, function(err, data) {

                if (err) {
                    console.log(err, err.stack); // an error occurred
                    self.stateUpdater({chatVisible: false});
                }
                else {   //self.qurl = data.QueueUrl;
                    self.poller = setInterval(() => self.receiveMessage(data.QueueUrl), 5000);
                    self.stateUpdater({chatVisible: true});

                }
            });

        })
    };


    receiveMessage(qurl){
        const self = this;
        console.log("checking q")
        const params = {
            AttributeNames: [
                "SentTimestamp"
            ],
            MaxNumberOfMessages: 10,
            MessageAttributeNames: [
                "All"
            ],
            //QueueUrl: self.qurl,
            QueueUrl: qurl,
            WaitTimeSeconds: 10
        };

        self.messageReceiver = self.sqs.receiveMessage(params, function(err, data) {
            if (!self.disMounted) {
                if (err) {
                    console.log(err, err.stack); // an error occurred
                    clearInterval(this.poller);
                    if (this.messageReceiver) {
                        this.messageReceiver.abort();
                        console.log("aborting");
                    }
                    self.startChat();
                }
                else {
                    //console.log(data);

                    for (let i = 0; i < data.Messages.length; i++) {
                        const messageBody = JSON.parse(data.Messages[i].Body);

                        console.log(messageBody);

                        const message =
                            {
                                type: 'text',
                                text: messageBody.Message,
                                senderId:messageBody.MessageAttributes.senderId.Value,
                                username: messageBody.MessageAttributes.username.Value,
                                id:messageBody.MessageId,
                            };

                        self.stateUpdater({messageList: [...self.state.messageList, message]});
                        const params = {
                            QueueUrl: qurl, /* required */
                            ReceiptHandle: data.Messages[i].ReceiptHandle, /* required */
                        };
                        self.sqs.deleteMessage(params, function (err, data) {
                            if (err) console.log(err, err.stack); // an error occurred
                            else console.log(data);           // successful response
                        });
                    }
                }
            } else {
                return;
            }
        });
    }

    componentWillUnmount() {
        this.disMounted = true;
        clearInterval(this.poller);
        if (this.messageReceiver) {
            this.messageReceiver.abort();
            console.log("aborting");
        }
        console.log("[chat] dismounting");
        this.sqs = undefined;
    }



    stateUpdater(param){
        const self=this;
        if (! self.disMounted) {
            self.setState((prevState) =>{
                return {
                    ...prevState,
                    ...param
                }
            });
        }
    }

    onMessageWasSent(message){
        const self = this;
        /*
        senderId:"kuczi.akos@gmail.com",
                                username: "akos",
        */

        const messageAttributes = {
            senderId:
                {DataType:"String",
                    StringValue:this.props.userName
                },
            username:
                {DataType:"String",
                    StringValue:this.props.name
                },
        };
        const params = {
            Message: message.text,
            TopicArn: 'arn:aws:sns:us-east-1:210564894232:chat-'+self.props.videoSessionId,
            MessageAttributes: messageAttributes
        };

        const publishTextPromise =  self.sns.publish(params).promise();

        console.log(params);

    }


    render() {
        const chat = this.state.chatVisible ?  (<Launcher
            headerConfig={{
                headerName: 'i-chat',
                imageUrl: 'https://s3.amazonaws.com/webcontent0001-us-east-1.itmeadow.com/assets/common/images/ink2.png'
            }}
            onMessageWasSent={this.onMessageWasSent.bind(this)}
            messageList={this.state.messageList}
            showEmoji={true}
            userId={this.props.userName}
            showUsername={true}
        />) : null;

        return (
            <div >
                {/* <Launcher
                    agentProfile={{
                        teamName: 'react-chat-window',
                        imageUrl: 'https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png'
                    }}
                    onMessageWasSent={this.onMessageWasSent.bind(this)}
                    messageList={this.state.messageList}
                    showEmoji={false}
                    onFilesSelected={()=>{return null}}
                /> */}
                {/*this.state.greeting.join(",")*/}

                {chat}

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        token: state.token,
        loggedIn: state.loggedIn,
        userName: state.userName,
        name: state.name,
        cognitoUserName: state.cognitoUserName,
    }
};


export default withRouter(connect(mapStateToProps,null)(Chat));
