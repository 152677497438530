import React from 'react';
import classes from "./FormReg.module.css";
import {CountryDropdown} from "react-country-region-selector";
const formReg = (props) => {
    return(
        <form className={classes.Form} encType="application/x-www-form-urlencoded"
              onSubmit={(event )=>props.submit(event)}
        >
            <div className={classes.FormCell}>
                <input type="email" name="email" required placeholder="Email"/>
                <input type="password" name="password" required placeholder="Password"
                       value={props.passwords[0]}
                       onChange={(event) => props.passwordChange(event, 0)}
                />
                <input type="password" name="passwordre" required placeholder="Re-enter password"
                       value={props.passwords[1]}
                       onChange={(event) => props.passwordChange(event, 1)}
                />
            </div>

            <div className={classes.FormCell}>
                <input type="text" name="name"  required placeholder="Name"/>
                <input type="text" name="company" required placeholder="Company"/>

                <CountryDropdown className={classes.CountryDropdown}
                                 value={props.country}
                                 labelType={"full"}
                                 valueType={"short"}
                                 onChange={(event) => props.countryChange(event)}
                                 name="country"
                                 required
                />

            </div>
            <div className={classes.FormCell}>
                <select name="category" required>
                    <option value={""} key={0}>Select your profile</option>
                    {props.categories}
                </select>


            </div>
            <div>
                <button disabled={props.disabled}>Register</button>
            </div>

        </form>
    )
}

export default formReg;