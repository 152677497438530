import React, { Component } from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import {connect} from "react-redux";
import * as actions from "../store/actionCreaters/indexActionCreaters";
import Header from './Header/HeaderV2';
import PartnerReg from './PartnerReg/PartnerReg';
import VideoSessions from "./VideoSessions/VideoSessions";
import VideoSessionToJoin from "./VideoSessions/VideoSessionItem/VideoSessionToJoin/VideoSessionToJoin";
import MainFilter from "./UI/MainFilter/MainFilter";
import Chat from "./VideoSessions/VideoSessionItem/VideoScreen/Chat/Chat"
import Background from "../assets/images/bg14.jpg";
//import LeftSideBar from './LeftSidebar/LeftSideBar';
import classes from './App.module.css'
import Home from "./Home/Home";


import './App.module.css';

const routesObj = [
  {
    link: "/Registration",
    component: PartnerReg,
    authNeeded: false
  },
  {
    link: "/PublishedPresentations",
    component: VideoSessions,
    authNeeded: false
  },
  {
    link: "/JoinToVideoSession/:id",
    component: VideoSessionToJoin,
    authNeeded: false,
  },
  {
    link: "/test",
    component: Chat,
    authNeeded: false
  },
  {
    link: "/",
    component: MainFilter,
    authNeeded: false
  },

  {
    link: "*",
    component: Home,
    authNeeded: false,
  },


  // {
  //   link: "/ManagePresentation",
  //   component: ManagePresentation,
  //   authNeeded: true
  // }
];


class App extends Component {

  componentDidMount() {
    this.props.checkAuth();
    console.log("componentdidmount");
  }

  onRouteChanged(message) {
    this.props.setVideoSessionIdToJoin(message);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged(this.props.location +"->"+prevProps.location);
    }
  }

  render() {
    const routes = routesObj.filter( route => {
      return ! route.authNeeded || this.props.loggedIn
    }).map((route, index) => {
          return <Route path={route.link} exact component={route.component} key={index}/>
    });

    return (
        <div className={classes.hg}
             style={{backgroundImage:  `url(${Background})`,
               backgroundRepeat: "no-repeat",
               backgroundPosition: "right bottom",
               backgroundSize: "cover"
             } } >

          <BrowserRouter>
            <>
              <div className={classes.hg__header}>
                <Header/>
              </div>
              <div className={classes.hg__main} >
                <Switch>
                  {routes}
                </Switch>
              </div>
              <div className={classes.hg__left}>

              </div>
              <div className={classes.hg__right} ></div>
              <div className={classes.hg__footer}></div>
            </>
          </BrowserRouter>
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loggedIn: state.loggedIn,
  }
};

const mapDispatchToProps = (dispatch) =>{
  return {
    checkAuth: () => dispatch(actions.checkAuth()),
    downloadJWT: () => dispatch(actions.downloadJWT()),
    setVideoSessionIdToJoin: (route) => dispatch(actions.setVideoSessionIdToJoin(route)),

  }
};

export default connect(mapStateToProps,mapDispatchToProps)(App);
