import {Auth} from "aws-amplify";
import apgClientFactory from "aws-api-gateway-client";

 function apiGWRequest(url, path, method, body)  {
    return (Auth.currentCredentials().then( (creds) => {
                //'https://qz6vkm0o8g.execute-api.us-east-1.amazonaws.com'
            const config = {
                    invokeUrl:url, // REQUIRED
                    accessKey: creds.accessKeyId, // REQUIRED
                    secretKey: creds.secretAccessKey, // REQUIRED
                    sessionToken: creds.sessionToken, //OPTIONAL: If you are using temporary credentials you must include the session token
                    region: creds.cognito.config.region, // REQUIRED: The region where the API is deployed.
                    retries: 4, // OPTIONAL: Number of times to retry before failing. Uses axon-retry plugin.
                }

            //console.log("creds----------------------------------->",creds);


                const apigClient = apgClientFactory.newClient(config);
                return (
                    apigClient.invokeApi(null,path,method,{},body)
                        .then((result)=>{
                            console.log(result.data);
                            console.log("INVOKEAPI:SUCC---------------------------------------------------",result);
                            return result;
                        }).catch( function(error){
                        console.log("INVOKEAPI:err---------------------------------------------------",error);
                    })
                );
            }
        ).catch(err => {
            return (
                 {
                    data:{
                        result:{
                            greeting:"Unauthorized access",
                        }
                    }
                 }
            )}
            )
    );
}

export default apiGWRequest