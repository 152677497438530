import * as actionTypes from '../actionTypes/actionTypes';
import {Auth} from "aws-amplify";
import axios from 'axios';
import awsConfig from '../../configs/aswConfig';


export const setRegistered = () => {
    return {
            type: actionTypes.SETREGISTERED,
    };
}

export const commitCurrentVideoSessionToJoin = (videoSessionId) => {
    return {
        type: actionTypes.COMMITVIDEOSESSIONIDTOJOIN,
        curtentVideoSessionToJoin: videoSessionId,
    };
}

export const setLoggedOut = () => {
    return {
        type: actionTypes.LOGGEDOUT,
    };
};

export const setLoggedIn = (userName, name, storagePrefix, token, cognitoUserName) => {
    return {
        type: actionTypes.LOGGEDIN,
        userName: userName,
        name: name,
        storagePrefix: storagePrefix,
        token: token,
        cognitoUserName: cognitoUserName

    };
};


export const setJWT = (jwt) => {
    return {
        type: actionTypes.SETJWT,
        jwt: jwt,
    };
};

export const setCredential = (credential) => {
    return {
        type: actionTypes.SETCREDENTIAL,
        credential: credential,
    };
};

export const checkAuth = () => {
    return (dispatch) => {
        Auth.currentAuthenticatedUser().then( (cognitoUser) => {
            dispatch(
                setLoggedIn(cognitoUser.signInUserSession.idToken.payload.email,
                    cognitoUser.signInUserSession.idToken.payload.name,
                    cognitoUser.keyPrefix,
                    cognitoUser.signInUserSession.idToken.jwtToken,
                    cognitoUser.signInUserSession.idToken.payload["cognito:username"],

                )
            );
            console.log('[authActionCreaters.checkAuth:Success]',cognitoUser);
            console.log('[authActionCreaters.checkAuth:Success/keyPrefix]',cognitoUser.keyPrefix);

            })
            .catch( (rejected) => {

            dispatch(setLoggedOut());
            console.log('[authActionCreaters.checkAuth:Rejected]',rejected);

        });
    };
};

export const auth = (jsonString) => {
    return (dispatch) => {
        const objData = JSON.parse(jsonString);
        Auth.signIn(objData.email, objData.password).then( (result) => {
            dispatch(
                setLoggedIn(
                            objData.email,
                            result.signInUserSession.idToken.payload.name,
                            result.keyPrefix,
                            result.signInUserSession.idToken.jwtToken,

                )
            );

            console.log('[authActionCreaters.auth:Success:]',result);

        }).catch( (error) => {
            console.log('[authActionCreaters.auth:Error:]',error);
            dispatch(setLoggedOut());

        });
    };
};

export const logOut = () => {
    return (dispatch) => {
        Auth.signOut().then( (result) => {
            console.log('[authActionCreaters.logOut:Success:]',result);
        }).catch( (error) => {
            console.log(error);
        });
        dispatch(setLoggedOut());
    }
};

export const downloadJWT = () => {
    return (dispatch) => {
        //https://cognito-idp.us-east-1.amazonaws.com/us-east-1_07j9Z1dGF/.well-known/jwks.json
        const url = 'https://cognito-idp.' + awsConfig.region + '.amazonaws.com/' + awsConfig.userPoolId + '/.well-known/jwks.json';
        axios.get(url).then((result) => {
            const jwtData = JSON.stringify(result.data);
            dispatch(setJWT(jwtData));
            console.log('[authActionCreaters:downloadJWT:Success]', jwtData);
        }).catch((error) =>{
            console.log('[authActionCreaters:downloadJWT:Error]', error);
        });
  }
};

export const getCredential = () => {
    return (dispatch) => {
        Auth.currentCredentials().then( (credential) => {
            dispatch(setCredential(credential));
            console.log('[authActionCreaters:getCredentials:Success]',credential);
            return credential;

            }
        ).catch((error)=>{
            console.log("-----------------",error)
        });
    }
};

export const setVideoSessionIdToJoin = (videoSessionId) => {
    return (dispatch) => {
        dispatch(commitCurrentVideoSessionToJoin(videoSessionId));
    }
}