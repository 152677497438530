import React, { Component } from 'react';
import VideoScreen from "../VideoScreen/VideoScreen";
import {connect} from 'react-redux';
import { withRouter } from 'react-router-dom';
import classes from "./VideoSessionToJoin.module.css";

class VideoSessionToJoin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            hidden: true,
            joined: false,
            videoSession: null,
        };
        this.backToVideoSessions = this.backToVideoSessions.bind(this);
    }

    backToVideoSessions(){
        this.props.history.goBack();//push('/',{});

    }


    componentWillMount() {
        const receivedObject = this.props.location.state;

        this.setState( (prevState) => {
            return {
                ...prevState,
                videoSession: receivedObject,
            }
        });

        //if (!this.state.videoSession) this.backToVideoSessions();

    }

    render() {
        return (
            <div className={classes.General}>
                <VideoScreen videoSessionId={this.state.videoSession.c} closeVsScreen={this.backToVideoSessions}/>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        curtentVideoSessionToJoin: state.curtentVideoSessionToJoin,
    }
};
/*
const mapDispatchToProps = (dispatch) =>{
    return {
        checkAuth: () => dispatch(actions.checkAuth()),
    }
};
*/

export default connect(mapStateToProps,null)(withRouter(VideoSessionToJoin));


