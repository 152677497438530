import React, {Component} from "react";
import ReactDOM from 'react-dom';
import ReactPlayer from 'react-player'
import Spinner from '../../../../UI/Spinner/Spinner';
import classes from "./videoElement.module.css";


class VideoElement extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
        }
        this.handleIfVideoIsReady = this.handleIfVideoIsReady.bind(this);
    }


    handleIfVideoIsReady = () => {
        this.setState((prevState) => {
            return {
                ...prevState,
                loaded: true,
            }
        })
    }

    render() {
        const spinner =  ! this.state.loaded ? <Spinner/> : null;
        return (
            <>
                <div className={classes.General}>
                    {spinner}
                </div>
            <div className={classes["player-wrapper"]}>
                <ReactPlayer
                    className={classes["react-player"]}
                    id="remoteVideo"
                    url={this.props.src}
                    playing={true}
                    onReady={this.handleIfVideoIsReady}
                    width={"100%"}
                    height={"100%"}
                    controls={true}
                />
        </div>
                </>

        )
    }
}

export default VideoElement;