import * as actionTypes from '../actionTypes/actionTypes';
const initialState={
    loggedIn: false,
    userName: null,
    name: null,
    storagePrefix: null,
    jwt: null,
    credential: null,
    token: null,
    curtentVideoSessionToJoin: null,
    cognitoUserName: null,

}
const reducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.SETREGISTERED:
            return {
                ...state, succesRegistration : true
            }


        case actionTypes.LOGGEDIN:
            return {
                ...state,
                loggedIn : true,
                userName: action.userName,
                name: action.name,
                storagePrefix: action.storagePrefix,
                token: action.token,
                cognitoUserName: action.cognitoUserName,
            }

        case actionTypes.LOGGEDOUT:
            return {
                ...state,
                loggedIn : false,
                userName: null,
                name: null,
                storagePrefix: null,
                token: null,
            }

        case actionTypes.SETJWT:
            return {
                ...state,
                jwt : action.jwt,
            }

        case actionTypes.SETCREDENTIAL:
            return {
                ...state,
                credential : action.credential,
            }


        case actionTypes.COMMITVIDEOSESSIONIDTOJOIN:
            return {
                ...state,
                curtentVideoSessionToJoin : action.curtentVideoSessionToJoin,
            }

        default :
            return state;

    }

}

export default reducer;