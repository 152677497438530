//import React, { Component } from 'react';

import React  from 'react';

const videoSessionDescription = (props) => {
    return (
            <div>
                {props.description}
            </div>
        )

    }



export default videoSessionDescription;

