import React, {Component} from 'react';
/*import axios from 'axios';
import bcrypt from 'bcryptjs';
import {connect} from 'react-redux';
import * as actionTypes from '../../store/actionTypes';
*/

import  {Auth} from 'aws-amplify';



import Form from './Form/FormReg';
import SuccesReg from './SuccesReg/SuccesReg';
import classes from './PartnerReg.module.css';

import Spinner from '../UI/Spinner/Spinner';


class PartnerReg extends Component{
    constructor(props) {
        super(props);
        this.state = {
            country: '',
            region: '',
            axiosResult: null,
            axiosError: null,
            categories: [],
            passwords: ['',''],
            disabled: true,
            signUpInProgress: false,
            signupResult: null,
            signupError: null,
        };

        this.selectCountry = this.selectCountry.bind(this);
        //this.axiosPost = this.axiosPost.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleSubmitSignupCognito = this.handleSubmitSignupCognito.bind(this);
        //this.handleSubmit = this.handleSubmit.bind(this);
    }

    selectCountry = (val) => {
        this.setState( (prevState) => {
            return {country: val,}
            }
        );

    }
/*
    axiosPost = (jsonData) => {
        axios.defaults.baseURL = "https://2a70gj7col.execute-api.us-east-1.amazonaws.com";

        this.showSpinner();
        this.setState({
            axiosResult: null,
            axiosError: null,
            disabled: true,
        })

        axios.post('/Prod/registerclient-01',
            jsonData)
            .then((result)=>{

                this.setState((prevState)=> {
                    return {
                        axiosResult: result.data.status,
                        axiosError: result.data.message
                    }
                });

                console.log(result);
                this.showSpinner();

                this.setState((prevState) =>{
                    return {disabled: false,}
                });

             //   if (result.data.status==="201") {
                //       this.props.setRegistrationCompleted();
                //}


            }).catch( (result)=> {

            this.setState((prevState) =>{
                return {
                    axiosResult: "515",
                    axiosError: "Network error",
                }
            })


            console.log(result);
            this.showSpinner();

            this.setState( (prevState) =>{
                        return {
                            disabled: false,
                        }
                    }
                );

            });


    }
*/
//EgyszerEgyKutya123%
    handlePasswordChange = (event, index) => {
        const passwords = {...this.state.passwords};
        passwords[index] = event.target.value;
        if (passwords[0] === passwords[1]) {
            // const re = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})");
            const re = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})");
            console.log(re.test(passwords[0]));
            // this.setState((prevState) => {
            //         return {
            //             passwords: passwords,
            //             disabled: !re.test(passwords[0]),
            //         }
            //     }
            // );

            this.setState((prevState) => {
                return {
                    ...prevState,
                    passwords :  passwords,
                    disabled: !re.test(passwords[0]),
                }
            });
        } else {
            // this.setState((prevState) => {
            //         return {
            //             passwords: passwords,
            //             disabled: !(passwords[0] === passwords[1]),
            //         }
            //     }
            // );
            this.setState((prevState) => {
                return {
                    ...prevState,
                    passwords :  passwords,
                    disabled: !(passwords[0] === passwords[1]),

                }
            });

        }
    }
/*
    handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.target);
        console.log(data.get("password"));
        const salt = bcrypt.genSaltSync(10);
        const hash =  bcrypt.hashSync(data.get('password'), salt);
        data.set('password',hash);

        data.delete('passwordre');

        let objData = {};
        data.forEach((v,k) =>{
                objData[k]=v;
            }
        )
        const jsonData = JSON.stringify(objData);
        this.axiosPost(jsonData);
    };
*/
    cognitoSignup = (jsonString) => {
        const jsonData = JSON.parse(jsonString);
        console.log(jsonData.email);
        const userAttributes= {
            username: jsonData.email,
            password: jsonData.password,
            attributes:
                {
                    'name': jsonData.name,
                    'custom:country': jsonData.country,
                    'custom:company' : jsonData.company,
                    'custom:category' : jsonData.category,
                }
        };


        console.log(userAttributes);
        this.showSpinner();
        // this.setState({
        //     signupResult: null,
        //     signupError: null,
        //     disabled: true,}
        // );

        this.setState((prevState) => {
            return {
                ...prevState,
                signupResult: null,
                signupError: null,
                disabled: true,
            }
        });

        Auth.signUp(userAttributes).then((result)=>{
            console.log('Signed up success: '+JSON.stringify(result));
            this.showSpinner();

            /*this.setState({
                signupResult: "OK",
                disabled: false,}
            );*/

            this.setState((prevState) => {
                return {
                    ...prevState,
                    signupResult: "OK",
                    disabled: false,
                }
            });

        }).catch( (err)=>{
            const jsonError = JSON.parse(JSON.stringify(err));
            console.log('Signed up: '+jsonError.message);
            this.showSpinner();
            this.setState({
                signupResult: jsonError.message,
                disabled: false,}
            );
        })
    }

    handleSubmitSignupCognito = (event) =>{
        event.preventDefault();
        const data = new FormData(event.target);
        console.log(data.get("password"));
        data.delete('passwordre');
        let objData = {};
        data.forEach((v,k) =>{
                objData[k]=v;
            }
        )
        const jsonData = JSON.stringify(objData);

        this.cognitoSignup(jsonData);
    };

    showSpinner = () => {
        this.setState( (prevState)=>{
            return {
                signUpInProgress: !this.state.signUpInProgress,

            }
        });
    };

    render() {

        const categories=  [
            'IT',
            'Health',
            'Veterinary',
            'Other'
        ].map((category, index) => {
            return <option value={category} key={index+1}>{category}</option>
        });

        const newRegistration = (
            <Form submit={this.handleSubmitSignupCognito}
              countryChange = {this.selectCountry}
              country = {this.state.country}
              categories = {categories}
              passwords = {this.state.passwords}
              passwordChange = {this.handlePasswordChange}
              disabled = {this.state.disabled}
            />)
        const result = (
            <SuccesReg/>
        )

        const display = this.state.signupResult === "OK" ? result : newRegistration;
        const errorMessage = this.state.signupResult === "OK" ? null: (
                <p>{this.state.signupResult}</p>
            );

        /*const display = this.props.succesRegistration ? result : newRegistration;
        const errorMessage = this.props.succesRegistration ? null: (
            <p>{this.state.axiosError}</p>
        );
        */

        const spinner = this.state.signUpInProgress ? <Spinner/>: null;

        return(
            <div className={classes.PartnerReg}>
            {display}
            {spinner}
            {errorMessage}
            </div>

        );

    }
}

export default PartnerReg;