import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';
import {createStore, applyMiddleware, compose} from "redux";
import reducer from './store/reducers/reducer';
import {Provider} from 'react-redux';
import Amplify from 'aws-amplify';
import thunk from 'redux-thunk';
import awsConfig from './configs/aswConfig'

// Add a request interceptor
axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
}, function (error) {
    // Do something with response error
    return Promise.reject(error);
});

Amplify.configure(awsConfig);

const middleWare = (store) => {
    return (next) => {
        return (action) => {
            console.log('[MiddleWare dispatching:]', action.type );

            const result = next(action);
            console.log('[MiddleWare next state:] ',store.getState());
            return result;
        }
    }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;



const store = createStore(reducer,composeEnhancers(applyMiddleware(thunk,middleWare)));

ReactDOM.render(<Provider store={store}> <App /> </Provider>, document.getElementById('root'));



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
