import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    withRouter
} from 'react-router-dom'


import * as actions from '../../../../store/actionCreaters/indexActionCreaters';

import FormLogin from './FormLogin/FormLogin';
import FormLogout from './FormLogout/FormLogout';

class Authentication extends Component {
    constructor(props){
        super(props);
        this.state={
            afterLogin : false,
        };

        this.handleLogin = this.handleLogin.bind(this);

    }

    handleLogin = (event) =>{
        event.preventDefault();
        const data = new FormData(event.target);
        let objData = {};
        data.forEach((v,k) =>{
                objData[k]=v;
            }
        );

        const jsonString = JSON.stringify(objData);
        this.setState( (prevState) => {
            return {
                ...prevState,
                afterLogin: true
            }
        } );

        this.props.auth(jsonString);

    };

    handleLogOut = (event) =>{
        event.preventDefault();
        this.props.logOut();
    };

    componentWillMount() {
        if (this.props.loggedIn && this.state.afterLogin) {
            this.setState( (prevState) => {
                return {
                    ...prevState,
                    afterLogin: false,
                }
            } );

            this.props.history.push('/');
        }

    }

    render(){
        const showLogout = <FormLogout logout={this.handleLogOut}/>

        const showLogin = <FormLogin login={this.handleLogin}/>

        const toDisplay = this.props.loggedIn ? showLogout: showLogin;

        return(
            <>
                {toDisplay}

            </>
        );
    }

}


const mapStateToProps = (state) => {
    return {
        userName: state.userName,
        loggedIn: state.loggedIn,
    }
};

const mapDispatchToProps = (dispatch) =>{
    return {
        logOut: () => dispatch(actions.logOut()),
        auth: (jsonString) => dispatch(actions.auth(jsonString)),
    }
};

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Authentication));
