//https://github.com/shanet/WebRTC-Example/blob/master/client/webrtc.js
import React, { Component } from 'react';

import VideoElement from "./VideoElement/videoElement";
import Chat from "./Chat/Chat";
import poster from "../../../../assets/images/spinner.gif";
import Spinner from '../../../UI/Spinner/Spinner';
import classes from './VideoScreen.module.css';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import $ from 'axios';

import * as actions from "../../../../store/actionCreaters/indexActionCreaters";



class VideoScreen extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ws: {},
            remoteVideoElement: {},
            webRtcPeer:{},
            keepAlive: {},
            iceState:  {},
            sessionFinished: false,
            everConnected: false,
            videoSource: null,
            turnServerAddress: null,
            componentMounted: true,
        }

        this.disMounted = false;

        this.sendMessage = this.sendMessage.bind(this);
        this.onLocalIceCandidate = this.onLocalIceCandidate.bind(this);
        this.handleOpen = this.handleOpen.bind(this);

        this.processMessage = this.processMessage.bind(this);
        this.registerNewServerCandidate = this.registerNewServerCandidate.bind(this);

        this.createOffer = this.createOffer.bind(this);
        this.registerSdpAnswerFromServer = this.registerSdpAnswerFromServer.bind(this);
        this.sendKeepAlive = this.sendKeepAlive.bind(this);
        this.sendLocalCandidates = this.sendLocalCandidates.bind(this);
        this.startIce = this.startIce.bind(this);
        this.setVideoSource = this.setVideoSource.bind(this);
    }


    componentWillUnmount(){

        let self = this;
        if (self.state.webRtcPeer != null) {
            try {
                self.state.webRtcPeer.close();    
            } catch (e) {
                
            }
            
        }

        if (self.state.ws !=null ) {
            self.state.ws.close();
        }


        this.props.setVideoSessionIdToJoin(null);
        this.setState((prevState)=>{
            return {
                ...prevState,
                componentMounted: false,
            }
        })
        this.disMounted =  true;

    }

    stateUpdater = (param)=>{
        console.log("Dismounted:", this.disMounted);
        if (! this.disMounted) {
            this.setState((prevState) =>{
                return {
                    ...prevState,
                    ...param
                }
            });
        }
    }

    sendMessage(message, functionName){
        let self = this;
        let jsonMessage = JSON.stringify(message);
        console.log("SendMessage from: "+functionName, message);
            self.state.ws.send(jsonMessage);
    }


    onLocalIceCandidate(candidate){
        let self = this;
        let messageToServer = {
            id : "browserCandidate",
            browserCandidate: candidate
        }
        self.sendMessage(messageToServer,"onLocalIceCandidate");
    }

    registerNewServerCandidate(message){
        let self = this;
        self.state.webRtcPeer.addIceCandidate(message.candidate).then(()=>{
            console.log("Added: ",message.candidate);
        }).catch(e =>{
            console.error("Failed to add remoteIce Candindate",message.candidate);
        })
    }



    processMessage(message) {
        console.log(message.data);
        let receivedJson = JSON.parse(message.data);
        console.log(receivedJson);
    }

    getRemoteVideoElement(){
        console.log("RemoteVIDEO",document.querySelector('videoRemote'));
        return document.querySelector('videoRemote');
    };

    onIceConnectionChange = (e) => {
        let self = this;
        let iceConnectionState = self.state.webRtcPeer.iceConnectionState;

        console.log("ICE connection state:",iceConnectionState);

        if (self.state.componentMounted) {
            self.setState({
                iceState:iceConnectionState
            });
        }

        switch (iceConnectionState) {
            case "new":
                break;
            case "connected":
                break;
            case "disconnected":
                self.props.closeVsScreen();
                break;
            default:
                console.log(iceConnectionState);
                break;
        }
    }


    startIce = () => {
        let self = this;

        const iceServers = [
            {"urls": [
                "stun:stun.l.google.com:19302",
                "stun:stun1.l.google.com:19302",
                "stun:stun2.l.google.com:19302",
                "stun:stunserver.org:3478",
            ]
            },
            {"urls": [
               // "turn:63.34.22.155:443?transport=udp"
                "turn:"+this.state.turnServerAddress+":443?transport=udp"
            ],
                "username": "kurento",
                "credential": "kurento"
            },
            {"urls": [
                //"turn:63.34.22.155:443?transport=tcp"
                    "turn:"+this.state.turnServerAddress+":443?transport=tcp"
            ],
                "username": "kurento",
                "credential": "kurento"
            }
        ]

        const configuration = {
            iceServers : iceServers,
            //iceTransportPolicy : 'relay'
            iceTransportPolicy : 'all'
        }

        //self.state.webRtcPeer =
        let localWebRtcPeer = new RTCPeerConnection(configuration);
        let offerOptions = {'offerToReceiveAudio':true,'offerToReceiveVideo':true};
        localWebRtcPeer.oniceconnectionstatechange = self.onIceConnectionChange

        localWebRtcPeer.onicecandidate = self.sendLocalCandidates;
        //localWebRtcPeer.onaddstream = self.setVideoSource;
        localWebRtcPeer.ontrack = self.setVideoSource;
        localWebRtcPeer.createOffer(offerOptions).then(self.createOffer).catch(self.generalErrorHandler);
        self.setState({webRtcPeer : localWebRtcPeer});

    }

    sendLocalCandidates(event){
        let self = this;
        console.log("My candidate:",event.candidate);
        if ( event.candidate != null ) {
            let messageToServer = {
                id : "browserCandidate",
                browserCandidate: event.candidate
            }
            self.sendMessage(messageToServer,"onLocalIceCandidate");
        }
    }

    setVideoSource(event) {
        //let stream = event.stream;
        let stream = event.streams;
        //this.state.remoteVideoElement.src = stream;
        //let video = document.getElementById("videoRemote");
        //video.srcObject = stream[0];
        this.setState((prevState) =>{
            return {
                ...prevState,
                videoSource: stream[0],
            }
        })

    }

    createOffer(sdpOffer){
        let self = this;
        //if (error) return console.log(error);
        console.log("Sending offer");
        let message = {
            id : "startMessageFromClient",
            sdpOfferFromClient : sdpOffer.sdp,
            videourl : self.props.videoSessionId
        }

        self.state.webRtcPeer.setLocalDescription(sdpOffer).then(self.sendMessage(message,"onOffer"), function () {
                // if we received an offer, we need to answer
            console.log("semmi");
        }
            );
        console.log(JSON.stringify(message));
    }

    registerSdpAnswerFromServer(message){
        let self = this;
        let sdp = {
            type: "answer",
            sdp: message.sdpAnswerFromServer
        }


        self.state.webRtcPeer.setRemoteDescription(new RTCSessionDescription(sdp),
                function(){
                    console.log('Remote SDP is registered:', sdp)
                },
                function (err) {
                    console.log('Error occured during remote SDP registration')
                }
        );
    }

    generalErrorHandler(error) {
        console.error(error);
    }

    handleOpen() {
        let self = this;
        self.setState({
            iceState: {},
            webRtcPeer: null
        })
        self.startIce();
    }

    sendKeepAlive() {
        let self = this;
        console.log("sending keepalive");
        let keepAliveMessage = {
            id : "keepAlive"
        }
        self.sendMessage(keepAliveMessage,"Send keepalive");
    }



    componentDidMount() {
        let self = this;
        let videoSessionId = this.props.videoSessionId;

        const serverName = 'listener-'+videoSessionId.replace("videosession","").replace(":","")+'.itmeadow.com';
        const url = 'wss://'+serverName+':443/videocall/' + videoSessionId;
        const ws = new WebSocket(url);

        ws.addEventListener('open', self.wsOpenListener);
        ws.addEventListener('message', self.wsMessageListener);
        ws.addEventListener('close', self.wsCloseListener);
        ws.addEventListener('error', self.wsErrorListener);

        self.setState({
            ws: ws,
            remoteVideoElement: self.getRemoteVideoElement()
        });
        this.wsCloseListener();
        this.props.setVideoSessionIdToJoin(self.props.videoSessionId);

        $.defaults.baseURL = "https://a77rgykiy0.execute-api.us-east-1.amazonaws.com/0001";
        //const url = "https://k4djcmsig3.execute-api.us-east-1.amazonaws.com/dev/returnvideosessionsget?videoSessionId=qy1p98vrj41j";
        //const url = "https://k4djcmsig3.execute-api.us-east-1.amazonaws.com/dev/returnvideosessionsget?vendor=Netapp&ownerName=Arrow";

        const locationData='{"location" : "EU"}';


        $.post("/getsharedturnserveraddress",locationData).then(response => {
            const apiGwResponse = response.data;

            if (apiGwResponse.status!=="200") {
                this.props.history.goBack();//push('/',{});
            }

            this.stateUpdater({
                turnServerAddress: apiGwResponse.ipOfTurnServer,
            });            // this.setState({dowloadedVideoSessions: dowloadedVideoSessions})


        }).catch((error) => {
            console.log("Something went wrong")
            this.props.history.goBack();//push('/',{});
        });



    }


    wsOpenListener = (event) => {
        const self = this;

        console.log("Websocket is opened");
        let keepAlive = setInterval(self.sendKeepAlive, 5000);

        self.setState({
            iceState: {},
            keepAlive: keepAlive
        })

        self.handleOpen();

        console.log("Eventlistener added");

    }

    wsMessageListener = (event) => {
        const self = this;

        console.error("ezaz::::::", event.data);
        self.processMessage(event);
        let processedMessage = JSON.parse(event.data);
        switch (processedMessage.id) {
            case "serverNewCandidate":
                self.registerNewServerCandidate(processedMessage);
                break;
            case "newVideoInfo":
                break;
            case "playEndFromServer":
                //self.props.closeVsScreen();
                self.setState({
                    sessionFinished:true
                })
                break;
            case "errorFromAppServer":
                console.log("errorFromAppServer: " + event.data);
                break;
            case "sdpAnswerMessageFromServer":
                self.registerSdpAnswerFromServer(processedMessage);
                break;
            default:
                break;
        }
    }


    wsCloseListener = (event) => {
        console.log("Az event:", event);
        const self = this;
        let videoSessionId = this.props.videoSessionId;

        if (  self.state.sessionFinished === true ) {
            console.log("Session finished: ",this.state.sessionFinished);
            clearInterval(self.state.keepAlive);
            console.log("keepAlive stopped");
        } else {
            if (self.state.keepAlive) {
                clearInterval(self.state.keepAlive);
            }

        }
    }

    wsErrorListener = () => {
        alert("Unable to connect to presentation. Please check your firewall settings");
        const self = this;
        self.props.history.goBack();
    }


    render() {

        let message=null;
        let hiddenStyle = {
            display: 'none'
        };

        if (this.state.iceState === "connected" || this.state.iceState === "completed") {
            message=null;
            hiddenStyle = null;

        } else if (this.state.iceState === "failed") {
                    message = <h4>Error connecting to presentation.</h4>;
        } else {

            message = <Spinner/>;

        }
        const simpleVideoSessionId = this.props.videoSessionId.replace("videosession:","");
        const chat = this.props.loggedIn ?  (
            <Chat userId={this.props.userName} videoSessionId={simpleVideoSessionId}/>
        ) : null;


        return (
            <div className={classes.General}>

                {message}

                <div style={hiddenStyle}>
                    {/* <video controls={true} id="videoRemote" autoPlay  className={classes.Video} /> */}
                    <VideoElement id={"videoRemote"}  src={this.state.videoSource} />
                    {chat}
                </div>
            </div>
        )

    }
}

const mapStateToProps = (state) => {
    return {
        curtentVideoSessionToJoin: state.curtentVideoSessionToJoin,
        loggedIn: state.loggedIn,

    }
};

const mapDispatchToProps = (dispatch) =>{
    return {
        setVideoSessionIdToJoin: (videoSessionId) => dispatch(actions.setVideoSessionIdToJoin(videoSessionId)),
    }
};


export default connect(mapStateToProps,mapDispatchToProps)(withRouter(VideoScreen));

