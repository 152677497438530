import React from 'react';
const formLogin = (props) => {
    return(
        <form className="form-inline my-2 my-lg-0" encType="application/x-www-form-urlencoded"
              onSubmit={(event )=>props.login(event)}
        >
            <div className="input-group input-group-sm mb-1 mr-sm-2">
                <div className="input-group-prepend">
                    <div className="input-group-text">@</div>
                </div>
                <input type="email"  name="email"  className="form-control  form-control-sm mr-sm-2" placeholder="Username"/>
                <input type="password" name="password" placeholder="Password" className="form-control form-control-sm mr-sm-2"/>
                <button type="submit" className="btn btn-success btn-sm">Login</button>

            </div>



        </form>
    )
}

export default formLogin;