import React, {Component} from "react";
import {connect} from 'react-redux';
import {withRouter} from "react-router-dom";
import VideoSessionItem from "../../VideoSessions/VideoSessions";
import classes from "./MainFilter.module.css";
import Vet from "../../../assets/images/vet1.png"
import Health from "../../../assets/images/Health1.png"
import IT from "../../../assets/images/IT1.png"
import Other from "../../../assets/images/Other1.png"
import * as Links from "../../../configs/links";

const mergedClass= [
    classes.MainFilter,
    "card text-center"
].join(" ");



class MainFilter extends Component {

    constructor(props) {
        super(props);

    }

    setFilter=(filter)=>{
        //this.props.history.push(Links.LISTPRESENTATIONS +'?' + "category="+filter);
        this.props.history.push(Links.LISTPRESENTATIONS +'?' + "j="+filter);
    }




    render() {

        return (
            <div className="card-columns">


                <div className={mergedClass} onClick={this.setFilter.bind(this,"Vet")}>
                    <img className="card-img" src={Vet} alt="Card image" style={{width:"200px",height:"200px"}} />
                </div>

                <div className={mergedClass} onClick={this.setFilter.bind(this,"Health")}>
                    <img className="card-img" src={Health} alt="Card image" style={{width:"200px",height:"200px"}}/>
                </div>

                <div className={mergedClass} onClick={this.setFilter.bind(this,"IT")}>
                    <img className="card-img" src={IT} alt="Card image" style={{width:"200px",height:"200px"}}/>
                </div>


                <div className={mergedClass} onClick={this.setFilter.bind(this,"Other")} >
                    <img className="card-img" src={Other} alt="Card image" style={{width:"180px",height:"180px"}} />
                </div>






            </div>
        )
    }
}




const mapStateToProps = (state) => {
    return {
        loggedIn: state.loggedIn,
    }
};

export default withRouter(connect(mapStateToProps,null)(MainFilter));
