import React from 'react';
import classes from './Spinner.module.css';

const spinner = (props) => {
    return (
        <div className={classes.Outer} >
            <div className={classes.Spinner}/>

        </div>
    )
}

export default spinner;
