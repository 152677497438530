import React from 'react';
import {NavLink} from "react-router-dom";



const Href = (props) => {
    //            <a style={props.style} onClick={() =>props.onclick(props.index)} href={props.link}>{props.caption}</a>
    return (
        <NavLink to={props.link} style={props.style}> {props.caption}</NavLink>
    )
}

export default Href;