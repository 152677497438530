import {Auth} from "aws-amplify";

function getCredential(){
    return (
        Auth.currentCredentials().then( (creds) =>{
            console.log(creds);
            return creds;
        }).catch((reason) => {
            console.log(reason);
            return null;
        })
    )
}

export default getCredential;