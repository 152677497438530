import React from 'react';
import classes from './SuccessReg.module.css';

const successReg = (props) => {
    return(
        <div className={classes.SuccesReg} >
            <p> Good job. <span>We send the details how to continue.</span></p>
        </div>
    )
}

export default successReg;